<template>
  <div class="home">
    <div class="guest">
      <h1>{{ $t("19") }}</h1>
      <p>
        {{ $t("20-1") }}<br />
        {{ $t("20-2") }}
      </p>
      <div class="form">
        <p>{{ $t("user-data-name") }}</p>
        <input type="text" v-model="username" />
        <p>{{ $t("user-data-phone-num") }}</p>
        <input type="text" v-model="phone" />
        <p>{{ $t("user-data-dept-name") }}</p>
        <input type="text" v-model="department" />
        <p>{{ $t("footer-data-company-email") }}</p>
        <input type="text" v-model="email" />

        <div v-if="error" class="error">
          {{ errorText }}
        </div>
      </div>

      <div class="buttonWrap flexB">
        <button @click="$router.go(-1)">{{ $t("btn-cancel") }}</button>
        <button class="point" @click="submit">{{ $t("26") }}</button>
      </div>
      <button
        class="joinBtn"
        @click="
          $router.push(
            `/${accountId}/join?workerType=${workerType}&device=${device}`
          )
        "
      >
        회원가입 바로가기
      </button>
      <span>
        {{ $t("login-info-available") }}
        {{ $t("login-info-available-illegally") }}
      </span>
      <Footer />
    </div>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import { guestLogin } from "@/api/login";
export default {
  components: { Footer },
  data() {
    return {
      error: false,
      username: "",
      phone: "",
      department: "",
      email: "",
      errorText: "",
      accountId: "",
      device: "",
      workerType: "",
    };
  },
  created() {
    this.accountId = this.$route.params.accountId;
    this.device = this.$route.query.device;
    this.workerType = this.$route.query.workerType;
  },
  methods: {
    submit() {
      if (
        !this.username ||
        !this.phone ||
        !this.department ||
        !this.accountId ||
        !this.email
      ) {
        this.error = true;
        if (!this.username) {
          this.errorText = this.$t("alert-message-check-name");
        } else if (!this.phone) {
          this.errorText = this.$t("alert-message-check-phone-num");
        } else if (!this.department) {
          this.errorText = this.$t("alert-message-check-dept");
        } else if (!this.email) {
          this.errorText = this.$t("alert-message-check-id");
        } else if (!this.accountId) {
          return;
        }
      }
      if (!this.error) {
        let data = {
          accountId: this.accountId,
          loginId: this.email,
          name: this.username,
          phone: this.phone,
          department: this.department,
          workerType: "Guest",
          device: this.device,
        };
        guestLogin(data).then((res) => {
          if (res.data.result == 0) {
            let params = JSON.stringify(res.data.data);
            window.Android.loginSuccess(params);
          } else {
            alert(res.data.message);
          }
        });
      }
    },
  },
};
</script>
